var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('BaseMasterFieldBlock',{attrs:{"title":"Название активности","description":"Это название будет использоваться в отчетах о работе активности."},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('base-text-field',{attrs:{"validate-on-blur":true,"placeholder":"Название активности","outlined":"","counter":"","minlength":"1","maxlength":"255","rules":[
            function (v) { return !!v || 'Название обязательно'; },
            function (v) { return v.length <= 255 || 'Название не должно быть более 255 символов'; }
          ]},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]},proxy:true}])}),_c('BaseMasterFieldBlock',{attrs:{"title":"Время работы активности","description":"После окончания указанного периода времени активность самостоятельно отключится."},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-row',[_c('v-col',[_c('base-datetime-picker-field',{staticClass:"date-start",attrs:{"placeholder":"Дата и время начала работы","rules":[
                function (v) { return _vm.validateDates(v); } ],"clearable":"","validation-placement":"top"},model:{value:(_vm.model.start_at),callback:function ($$v) {_vm.$set(_vm.model, "start_at", $$v)},expression:"model.start_at"}})],1),_c('v-col',[_c('base-datetime-picker-field',{attrs:{"rules":[
                function (v) { return _vm.validateDates(v); } ],"placeholder":"Дата и время окончания работы","clearable":"","validation-placement":"top"},model:{value:(_vm.model.finish_at),callback:function ($$v) {_vm.$set(_vm.model, "finish_at", $$v)},expression:"model.finish_at"}})],1)],1)]},proxy:true}])}),_c('BaseMasterFieldBlock',{attrs:{"title":"Описание","description":"Описание активности позволяет сделать пояснение для сотрудников, какие действия будут выполняться в результате работы активности. Клиенты этот текст не видят."},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('base-text-area',{attrs:{"placeholder":"Описание","rules":[
            function (v) { return (!v || v.length <= 4096) || 'Описание не превышает 4096'; } ],"maxlength":"4096","hide-details":""},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})]},proxy:true}])}),_c('BaseMasterFieldBlock',{attrs:{"title":"Режим запуска","description":"Выберите один из режимов запуска активности и настройте её параметры."},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-row',[_c('v-col',[_c('v-radio-group',{staticClass:"mt-0",attrs:{"hide-details":"","row":""},model:{value:(_vm.emitMode),callback:function ($$v) {_vm.emitMode=$$v},expression:"emitMode"}},_vm._l((_vm.emitModeList),function(item){return _c('v-radio',{key:item.id,attrs:{"label":item.text,"value":item.id,"on-icon":"$iconify_fa-solid-dot-circle","off-icon":"$iconify_bi-circle"}})}),1)],1)],1),(_vm.emitModeObject && _vm.emitModeObject.comment)?_c('v-row',{staticStyle:{"margin-top":"12px"}},[_c('v-col',{staticClass:"pa-0",staticStyle:{"margin-right":"8px","margin-left":"10px"},attrs:{"cols":"auto"}},[_c('v-icon',[_vm._v("$iconify_feather-info")])],1),_c('v-col',{staticClass:"body-m-regular neutral-600--text pa-0"},[_vm._v(" "+_vm._s(_vm.emitModeObject.comment)+" ")])],1):_vm._e(),(_vm.emitMode=== _vm.ProgramEventBroadcaster.EMIT_MODE_ENUM.EVENT.id )?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-autocomplete',{attrs:{"loading":_vm.GetEventListAction,"items":_vm.accountEventPickList,"rules":[
                function (v) { return !!v || 'Выберите событие'; } ],"placeholder":"Выберите событие","item-text":"name","item-value":"id","outlined":"","auto-select-first":""},on:{"input":_vm.onListenEventChange},model:{value:(_vm.model.listen_event),callback:function ($$v) {_vm.$set(_vm.model, "listen_event", $$v)},expression:"model.listen_event"}})],1)],1):_vm._e(),(_vm.emitMode=== _vm.ProgramEventBroadcaster.EMIT_MODE_ENUM.PERIOD.id && _vm.model.period )?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Запускать процесс каждые ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('base-text-field',{style:({width: '72px'}),attrs:{"validate-on-blur":true,"key-filter-regexp":/(\d|Delete|Backspace|Enter)/,"outlined":"","rules":[
                function (v) { return !!v || 'обязательно'; } ]},model:{value:(_vm.model.period.value),callback:function ($$v) {_vm.$set(_vm.model.period, "value", _vm._n($$v))},expression:"model.period.value"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{style:({width: '150px'}),attrs:{"items":_vm.periodTypeList,"item-text":"text","item-value":"id","placeholder":"Период","outlined":"","hide-details":"","rules":[
                function (v) { return !!v || 'Выберите периодичность'; } ]},model:{value:(_vm.model.period.type),callback:function ($$v) {_vm.$set(_vm.model.period, "type", $$v)},expression:"model.period.type"}})],1)],1):_vm._e()]},proxy:true}])}),(!!_vm.model.listen_event && _vm.queryRules.length>0)?_c('BaseMasterFieldBlock',{attrs:{"title":"Фильтр события","description":"Описание поля"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('query-builder',{attrs:{"rules":_vm.queryRules},model:{value:(_vm.model.event_filter),callback:function ($$v) {_vm.$set(_vm.model, "event_filter", $$v)},expression:"model.event_filter"}})]},proxy:true}],null,false,1377581620)}):_vm._e(),_c('v-row',[_c('v-col',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.actionShow),expression:"actionShow"}],staticClass:"master-next-btn",attrs:{"disabled":!_vm.formValid,"color":"primary"},on:{"click":_vm.onNextClick}},[_c('p',{staticClass:"body-m-semibold mb-0"},[_vm._v(" "+_vm._s(_vm.actionText)+" ")]),(!!_vm.actionIcon)?_c('v-icon',{attrs:{"rigth":!_vm.actionLeft}},[_vm._v(" "+_vm._s(_vm.actionIcon)+" ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }