<template>
  <v-container class="pa-0">
    <v-form
      ref="form"
      v-model="formValid"
    >
      <BaseMasterFieldBlock
        title="Название активности"
        description="Это название будет использоваться в отчетах о работе активности."
      >
        <template v-slot:input>
          <base-text-field
            v-model="model.name"
            :validate-on-blur="true"
            placeholder="Название активности"
            outlined
            counter
            minlength="1"
            maxlength="255"
            :rules="[
              v => !!v || 'Название обязательно',
              v => v.length <= 255 || 'Название не должно быть более 255 символов'
            ]"
          />
        </template>
      </BaseMasterFieldBlock>

      <BaseMasterFieldBlock
        title="Время работы активности"
        description="После окончания указанного периода времени активность самостоятельно отключится."
      >
        <template v-slot:input>
          <v-row>
            <v-col>
              <base-datetime-picker-field
                v-model="model.start_at"
                class="date-start"
                placeholder="Дата и время начала работы"
                :rules="[
                  v => validateDates(v),
                ]"
                clearable
                validation-placement="top"
              />
            </v-col>
            <v-col>
              <base-datetime-picker-field
                v-model="model.finish_at"
                :rules="[
                  v => validateDates(v),
                ]"
                placeholder="Дата и время окончания работы"
                clearable
                validation-placement="top"
              />
            </v-col>
          </v-row>
        </template>
      </BaseMasterFieldBlock>

      <BaseMasterFieldBlock
        title="Описание"
        description="Описание активности позволяет сделать пояснение для сотрудников, какие действия будут выполняться в результате работы активности. Клиенты этот текст не видят."
      >
        <template v-slot:input>
          <base-text-area
            v-model="model.description"
            placeholder="Описание"
            :rules="[
              (v) => (!v || v.length <= 4096) || 'Описание не превышает 4096',
            ]"
            maxlength="4096"
            hide-details
          />
        </template>
      </BaseMasterFieldBlock>

      <BaseMasterFieldBlock
        title="Режим запуска"
        description="Выберите один из режимов запуска активности и настройте её параметры."
      >
        <template v-slot:input>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="emitMode"
                class="mt-0"
                hide-details
                row
              >
                <v-radio
                  v-for="item in emitModeList"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id"
                  on-icon="$iconify_fa-solid-dot-circle"
                  off-icon="$iconify_bi-circle"
                />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row
            v-if="emitModeObject && emitModeObject.comment"
            style="margin-top: 12px;"
          >
            <v-col
              cols="auto"
              class="pa-0"
              style="margin-right: 8px; margin-left: 10px;"
            >
              <v-icon>$iconify_feather-info</v-icon>
            </v-col>
            <v-col class="body-m-regular neutral-600--text pa-0">
              {{ emitModeObject.comment }}
            </v-col>
          </v-row>
          <v-row
            v-if="emitMode=== ProgramEventBroadcaster.EMIT_MODE_ENUM.EVENT.id "
            align="center"
          >
            <v-col>
              <v-autocomplete
                v-model="model.listen_event"
                :loading="GetEventListAction"
                :items="accountEventPickList"
                :rules="[
                  v => !!v || 'Выберите событие',
                ]"
                placeholder="Выберите событие"
                item-text="name"
                item-value="id"
                outlined
                auto-select-first
                @input="onListenEventChange"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="emitMode=== ProgramEventBroadcaster.EMIT_MODE_ENUM.PERIOD.id && model.period "
            align="center"
          >
            <v-col cols="auto">
              Запускать процесс каждые
            </v-col>
            <v-col cols="auto">
              <base-text-field
                v-model.number="model.period.value"
                :validate-on-blur="true"
                :style="{width: '72px'}"
                :key-filter-regexp="/(\d|Delete|Backspace|Enter)/"
                outlined
                :rules="[
                  v => !!v || 'обязательно',
                ]"
              />
            </v-col>
            <v-col cols="auto">
              <v-select
                v-model="model.period.type"
                :style="{width: '150px'}"
                class=""
                :items="periodTypeList"
                item-text="text"
                item-value="id"
                placeholder="Период"
                outlined
                hide-details=""
                :rules="[
                  v => !!v || 'Выберите периодичность',
                ]"
              />
            </v-col>
          </v-row>
        </template>
      </BaseMasterFieldBlock>

      <BaseMasterFieldBlock
        v-if="!!model.listen_event && queryRules.length>0"
        title="Фильтр события"
        description="Описание поля"
      >
        <template v-slot:input>
          <query-builder
            v-model="model.event_filter"
            :rules="queryRules"
          />
        </template>
      </BaseMasterFieldBlock>

      <v-row>
        <v-col>
          <v-btn
            v-show="actionShow"
            :disabled="!formValid"
            color="primary"
            class="master-next-btn"
            @click="onNextClick"
          >
            <p class="body-m-semibold mb-0">
              {{ actionText }}
            </p>
            <v-icon
              v-if="!!actionIcon"
              :rigth="!actionLeft"
            >
              {{ actionIcon }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import ProgramEventBroadcaster from '@/models/program/broadcaster'
  import QueryBuilder from '@/components/query-builder'
  import BonusUnitSelect from '@/components/ModelSelect/BonusUnitSelect'
  import SegmentSelect from '@/components/ModelSelect/SegmentSelect'
  import NomenclatureSegmentSelect from '@/components/ModelSelect/NomenclatureSegmentSelect'
  import ShopSelect from '@/components/ModelSelect/ShopSelect'  

  export default {
    components: {
      QueryBuilder,
    },
    model: {
      prop: 'model',
      event: 'change',
    },
    props: {
      model: {
        type: Object,
        required: true,
      },
      actionText: {
        type: String,
        default: 'Далее',
      },
      actionLeft: Boolean,
      actionIcon: {
        type: String,
        default: '$iconify_heroicons-outline-arrow-right',
      },
      actionLoading: Boolean,
      actionShow: {
        type: Boolean,
        default: true,
      },
    },
    constants: {
      ProgramEventBroadcaster,
      periodTypeList: ProgramEventBroadcaster.PERIOD_ENUM.toList(),
      accountEventList: [
        { id: 'id', text: 'text' },
      ],
    },
    data () {
      return {
        formValid: false,
        GetEventListAction: false,
        emitModeList: [
          {
            id: ProgramEventBroadcaster.EMIT_MODE_ENUM.MANUAL.id,
            text: 'Ручной запуск',
            comment: 'Режим ручного запуска необходим в том случае, когда сотрудник сам выбирает, когда необходимо выполнить активность. Активность будет выполняться только после нажатия кнопки "Выполнить" в списке активностей. При этом, если попытка запуска будет произведена не в период, указанный в настройках активности, то она не выполнится. Такие активности можно запускать многократно нажимая кнопку "Выполнить" в списке активностей.',
          },
          {
            id: ProgramEventBroadcaster.PERIOD_ENUM.ONCE.id,
            text: 'Единоразово',
            comment: 'В этом режиме активность выполнится один раз автоматически, когда наступит время начала её работы. Если указанное время уже наступило, то такая активность будет запущена сразу после её включения в списке.',
          },
          {
            id: ProgramEventBroadcaster.EMIT_MODE_ENUM.PERIOD.id,
            text: 'По расписанию',
            comment: 'Этот режим позволит запускать активность с заданным интервалом времени. Все запуски будут происходить при включении активности в списке и при условии попадания в интервал времени работы активности, указанный в её настройках.',
          },
          {
            id: ProgramEventBroadcaster.EMIT_MODE_ENUM.EVENT.id,
            text: 'По событию',
            comment: 'Такой режим позволит настроить запуск активности по возникновению события пользователя. Такая активность будет запущена только для того пользователя, у которого возникло событие. В настройках запуска необходимо выбрать тип события, по которому будет произведен запуск.',
          },
          {
            id: ProgramEventBroadcaster.EMIT_MODE_ENUM.ACCOUNT.id,
            text: 'Запуск клиентом',
            comment: 'Такие активности запускаются только для клиентов нажавших кнопку в рассылке. Выбор запускаемой активности производится в настройках кнопки в шаблоне рассылки.',
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        accountEventPickList: 'company/event_broadcasters/accountEventPickList',
        bonusUnits: 'company/bonus_units/bonusUnits',
      }),

      emitModeObject () {
        return this.$_.findWhere(this.emitModeList, { id: this.emitMode })
      },

      queryRules () {
        if (this.model.emit_mode === ProgramEventBroadcaster.EMIT_MODE_ENUM.EVENT.id && this.model.listen_event) {
          const unitSelectRule = {
            type: 'custom-component',
            id: 'bonusUnitId',
            component: BonusUnitSelect,
            label: 'Валюта',
          }
          const segmentSelectRule = {
            type: 'custom-component',
            id: 'segmentId',
            component: SegmentSelect,
            label: 'Сегмент',
          }

          switch (this.model.listen_event) {
            case 'account.bonus.balance:change':
              return [
                {
                  type: 'numeric',
                  id: 'balance',
                  label: 'Баланс',
                },
                unitSelectRule,
              ]
            case 'account.bonus.operation':
              return [
                {
                  type: 'numeric',
                  id: 'value',
                  label: 'Сумма',
                },
                unitSelectRule,
                {
                  type: 'radio',
                  id: 'type',
                  choices: [
                    { value: 'CREDIT', label: 'Начисление' },
                    { value: 'DEBIT', label: 'Списание' },
                  ],
                  label: 'Тип операции',
                },
              ]
            case 'account.bonus.operation.credit':
            case 'account.bonus.operation.debit':
              return [
                {
                  type: 'numeric',
                  id: 'value',
                  label: 'Сумма',
                },
                unitSelectRule,
              ]
            case 'account.bonus.balance:max': return [unitSelectRule]
            case 'account.segments:attach':
            case 'account.segments:detach': return [segmentSelectRule]
            case 'account.sale:create':
            case 'account.sale:bonus':
            case 'account.sale:bonus.debit':
              return [
                {
                  type: 'numeric',
                  id: 'valueRub',
                  label: 'Сумма чека, руб.',
                },
                {
                  type: 'custom-component',
                  id: 'positionSegments',
                  operators: ['has'],
                  component: NomenclatureSegmentSelect,
                  props: { selectFirst: true },
                  label: 'Позиция из сегмента',
                  title: 'Имеется позиция из сегмента:',
                },
                {
                  type: 'custom-component',
                  id: 'shopId',
                  operators: [
                    {id: '=', label: 'равна'}, 
                    {id: '!=', label: 'не равна'}, 
                    {id: 'empty', label: 'не указана'}, 
                    {id: 'not_empty', label: 'указана'}, 
                  ],
                  component: ShopSelect,
                  props: { selectFirst: true, chips: false },
                  label: 'Торговая точка',
                  title: 'Торговая точка',
                },
              ]
            default:
              return []
          }
        } else {
          return []
        }
      },
      emitMode: {
        get: function () {
          if (this.model.emit_mode === ProgramEventBroadcaster.EMIT_MODE_ENUM.PERIOD.id) {
            return this.model.period && this.model.period.type === ProgramEventBroadcaster.PERIOD_ENUM.ONCE.id
              ? ProgramEventBroadcaster.PERIOD_ENUM.ONCE.id : ProgramEventBroadcaster.EMIT_MODE_ENUM.PERIOD.id
          } else {
            return this.model.emit_mode
          }
        },
        set: function (v) {
          this.model.emit_mode = v
          if (v === ProgramEventBroadcaster.EMIT_MODE_ENUM.PERIOD.id) {
            this.model.period = {
              type: ProgramEventBroadcaster.PERIOD_ENUM.DAY.id,
              value: null,
            }
          } else if (v === ProgramEventBroadcaster.PERIOD_ENUM.ONCE.id) {
            this.model.emit_mode = ProgramEventBroadcaster.EMIT_MODE_ENUM.PERIOD.id
            this.model.period = {
              type: ProgramEventBroadcaster.PERIOD_ENUM.ONCE.id,
              value: null,
            }
          } else {
            this.model.period = null
          }
        },
      },
    },
    created () {
      this.loadEventList()
    },
    methods: {
      ...mapActions({
        GetEventList: 'company/event_broadcasters/GetEventList',
        loadBonusUnits: 'company/bonus_units/loadBonusUnits',
      }),

      validate () {
        return this.$refs.form.validate()
      },
      async loadEventList () {
        try {
          this.GetEventListAction = true
          await this.GetEventList()
        } catch (e) {
          console.error(e)
        } finally {
          this.GetEventListAction = false
        }
      },

      validateDates (v) {
        if (this.model.start_at && this.model.finish_at) {
          return this.$moment(this.model.start_at).diff(this.$moment(this.model.finish_at)) < 0 || 'Дата окончания должна быть больше начала'
        } else {
          return true
        }
      },
      onNextClick () {
        if (this.validate()) {
          this.$emit('continue', true)
        }
      },
      onListenEventChange () {
        console.log('onListenEventChange')
        this.$nextTick(() => {
          this.model.event_filter = null
        })
      },
    },
  }
</script>
<style lang="scss" scoped>

@import '@/views/dashboard/form_component/_form-component.scss';
</style>
