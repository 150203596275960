<template>
  <v-select
    v-model="valueMutation"
    v-bind="$attrs"
    outlined
    hide-details
    :items="selectList"
    item-value="id"
    item-text="name"
    :loading="loadingAction"
    v-on="$listeners"
  />
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'BonusUnitSelect',
    model: {
      prop: 'value',
      event: 'update',
    },
    props: {
      value: { type: [Number, Object], default: undefined },
    },
    data () {
      return {
        loadingAction: false,

      }
    },
    computed: {
      ...mapGetters({
        programId: 'programId',
        bonusUnits: 'company/bonus_units/bonusUnits',
      }),
      selectList () {
        return this.bonusUnits
      },
      valueMutation: {
        get: function () {
          return this.value
        },
        set: function (v) {
          this.$emit('update', v)
        },
      },
    },
    watch: {
      programId (v) {
        this.startAction()
      },
    },
    created () {
      this.startAction()
    },

    methods: {
      ...mapActions({
        loadBonusUnits: 'company/bonus_units/loadBonusUnits',
      }),
      async startAction () {
        if (this.loadingAction) return
        try {
          this.loadingAction = true
          await this.loadBonusUnits(this.programId)
          if (this.selectList.length > 0 && !this.valueMutation) this.valueMutation = this.selectList[0].id
        } catch (error) {
          console.error(error)
        } finally {
          this.loadingAction = false
        }
      },

    },
  }
</script>
